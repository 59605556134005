@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

//The content within the collaspable area
.Collapsible__contentInner {
  padding: 10px;
  border-left-style: solid;
  border-width: 3px;
  border-color: red;
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  cursor: pointer;
  display: flex;
  font-weight: 400;
  font-size: 15px;
  position: relative;
  border-left-style: solid;
  border-width: 3px;
  border-color: red;
  padding: 10px;
  padding-left: 50px;
  color: black;
  align-items: center;

  &:before {
    font-family: "Material Icons";
    font-size: 24px;
    content: "\e315";
    position: absolute;
    display: block;
    left: 10px;
    transition: transform 300ms;
  }

  &.is-open {
    &:before {
      transform: rotateZ(90deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}
